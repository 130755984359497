{
	"name": "@e-tenant-hub/source",
	"version": "3.0.2",
	"license": "MIT",
	"scripts": {
		"start": "npx nx run-many --target=serve --all=true",
		"start:client-platform": "npx nx run client-platform:serve",
		"start:pay-rent-now": "npx nx run pay-rent-now:serve",
		"start:client-platform:staging": "npx nx run client-platform:serve:staging",
		"build:client-platform:release": "npx nx build client-platform --configuration=production",
		"lint": "nx affected:lint",
		"format": "nx format:write",
		"format:check": "nx format:check",
		"install:localhost-ssl": "powershell -NoProfile -ExecutionPolicy Unrestricted -Command ./scripts/set-localhost-ssl-certificate.ps1",
		"prepare": "husky"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "17.3.2",
		"@angular/cdk": "17.3.2",
		"@angular/common": "17.3.2",
		"@angular/compiler": "17.3.2",
		"@angular/core": "17.3.2",
		"@angular/forms": "17.3.2",
		"@angular/material": "17.3.2",
		"@angular/platform-browser": "17.3.2",
		"@angular/platform-browser-dynamic": "17.3.2",
		"@angular/router": "17.3.2",
		"@angular/service-worker": "17.3.2",
		"@maskito/angular": "^2.3.1",
		"@maskito/core": "^2.3.1",
		"@maskito/kit": "^2.3.1",
		"@nx/angular": "19.0.2",
		"angular-oauth2-oidc": "^17.0.2",
		"angular-oauth2-oidc-jwks": "^17.0.2",
		"dayjs": "^1.11.11",
		"file-saver": "^2.0.5",
		"libphonenumber-js": "^1.11.4",
		"ngx-cookie-service": "^17.1.0",
		"ngx-webstorage": "^13.0.1",
		"rxjs": "~7.8.0",
		"swiper": "^11.1.1",
		"tslib": "^2.3.0",
		"zone.js": "0.14.4"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "17.3.2",
		"@angular-devkit/core": "17.3.2",
		"@angular-devkit/schematics": "17.3.2",
		"@angular-eslint/eslint-plugin": "17.3.0",
		"@angular-eslint/eslint-plugin-template": "17.3.0",
		"@angular-eslint/template-parser": "17.3.0",
		"@angular/cli": "~17.3.0",
		"@angular/compiler-cli": "17.3.2",
		"@angular/language-service": "17.3.2",
		"@nx/eslint": "19.0.2",
		"@nx/eslint-plugin": "19.0.2",
		"@nx/jest": "19.0.2",
		"@nx/js": "19.0.2",
		"@nx/workspace": "19.0.2",
		"@schematics/angular": "17.3.2",
		"@swc-node/register": "1.8.0",
		"@swc/core": "~1.3.85",
		"@swc/helpers": "~0.5.2",
		"@types/file-saver": "^2.0.7",
		"@types/jest": "^29.4.0",
		"@types/node": "^18.16.9",
		"@typescript-eslint/eslint-plugin": "7.3.0",
		"@typescript-eslint/parser": "7.3.0",
		"autoprefixer": "^10.4.19",
		"eslint": "8.57.0",
		"eslint-config-prettier": "^9.0.0",
		"husky": "^9.0.11",
		"jest": "^29.4.1",
		"jest-environment-jsdom": "^29.4.1",
		"jest-preset-angular": "14.0.3",
		"jsonc-eslint-parser": "^2.1.0",
		"lint-staged": "^15.2.2",
		"nx": "19.0.2",
		"postcss": "^8.4.38",
		"prettier": "3.2.5",
		"prettier-plugin-organize-attributes": "^1.0.0",
		"prettier-plugin-organize-imports": "^3.2.4",
		"prettier-plugin-tailwindcss": "^0.5.14",
		"tailwind-scrollbar": "^3.1.0",
		"tailwindcss": "^3.4.3",
		"ts-jest": "^29.1.0",
		"ts-node": "10.9.1",
		"typescript": "5.4.3"
	},
	"volta": {
		"node": "20.12.2"
	}
}
