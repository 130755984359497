import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { APP_CONFIG } from "@e-tenant-hub/shared/config";
import { Observable } from "rxjs";

export interface IRequestOptions {
	headers?: HttpHeaders;
	observe?: "body";
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: "json";
	withCredentials?: boolean;
	body?: unknown;
}

@Injectable()
export class AuthHttpClient {
	private readonly appConfig = inject(APP_CONFIG);
	private httpClient = inject(HttpClient);

	/**
	 * HTTP GET request
	 * @param endPoint end point of the auth api
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return this.httpClient.get<T>(this.appConfig.authApiRoot + endPoint, options);
	}

	/**
	 * HTTP POST request
	 * @param endPoint end point of the patient api
	 * @param params body of request
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	public post<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
		return this.httpClient.post<T>(this.appConfig.authApiRoot + endPoint, params, options);
	}

	/**
	 * HTTP PUT request
	 * @param endPoint end point of the patient api
	 * @param params body of request
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	public put<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
		return this.httpClient.put<T>(this.appConfig.authApiRoot + endPoint, params, options);
	}

	/**
	 * HTTP DELETE request
	 * @param endPoint
	 * @param options
	 * @returns {Observable<T>}
	 */
	public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return this.httpClient.delete<T>(this.appConfig.authApiRoot + endPoint, options);
	}
}
