import { Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { environment } from "@e-tenant-hub/environments";
import { AppConfig } from "@e-tenant-hub/shared/config";
import {
	AuthConfig,
	LoginOptions,
	OAuthErrorEvent,
	OAuthInfoEvent,
	OAuthService,
	OAuthStorage,
	OAuthSuccessEvent,
} from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { BehaviorSubject, Observable, ReplaySubject, delay, filter, map, switchMap, tap } from "rxjs";
import { AuthorizationResult, User } from "../types";
import { CodeFlowAuthConfig } from "../types/code-flow-auth-config";
import { ROPCAuthConfig } from "../types/ropc-auth-config";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private readonly oauthService = inject(OAuthService);
	private readonly oauthStorage = inject(OAuthStorage);
	private readonly router = inject(Router);

	private _authorizationResult = new AuthorizationResult(false, false, false);
	private _isLoadDiscoveryDocumentDone = false;
	private _isIdentityConfigurationDone = false;
	private _isIdentityServerOnError = false;
	private _stateUrl?: string;
	private _useRopcFlow = false;

	// Subject
	private _isIdentityServerOnErrorSubject = new BehaviorSubject<boolean>(this._isIdentityServerOnError);
	private _authorizationResultSubject = new ReplaySubject<AuthorizationResult>(1);

	// Observable
	isIdentityServerOnError$: Observable<boolean> = this._isIdentityServerOnErrorSubject.pipe(
		tap((isIdentityServerOnError: boolean) => (this._isIdentityServerOnError = isIdentityServerOnError))
	);
	authorizationResult$: Observable<AuthorizationResult> = this._authorizationResultSubject.asObservable();

	private _onRetryOnFail$ = this._isIdentityServerOnErrorSubject.pipe(
		takeUntilDestroyed(),
		filter((isIdentityServerOnError) => isIdentityServerOnError),
		tap(() => {
			this.notifyAuthorizationResultChange();
		}),
		delay(10000),
		switchMap(() => {
			return this.initialize();
		})
	);

	private _onAuthErrorEvents$: Observable<OAuthErrorEvent> = this.oauthService.events.pipe(
		takeUntilDestroyed(),
		filter((e) => e instanceof OAuthErrorEvent),
		map((e) => e as OAuthErrorEvent),
		tap(async (e) => await this.handleErrorEvents(e))
	);

	private _onAuthSuccessEvents$: Observable<OAuthSuccessEvent> = this.oauthService.events.pipe(
		takeUntilDestroyed(),
		filter((e) => e instanceof OAuthSuccessEvent),
		map((e) => e as OAuthSuccessEvent),
		tap((e) => this.handleSuccessEvents(e))
	);

	private _onAuthInfoEvents$: Observable<OAuthInfoEvent> = this.oauthService.events.pipe(
		takeUntilDestroyed(),
		filter((e) => e instanceof OAuthInfoEvent),
		map((e) => e as OAuthInfoEvent),
		tap((e) => this.handleInfoEvents(e))
	);

	private _onAuthSilentRefreshEvents$: Observable<OAuthInfoEvent> = this.oauthService.events.pipe(
		takeUntilDestroyed(),
		filter((e) => ["silently_refreshed", "token_refreshed"].indexOf(e.type) > -1),
		map((e) => e as OAuthInfoEvent),
		tap(async () => await this.oauthService.loadUserProfile())
	);

	get idToken(): string {
		return this.oauthService.getIdToken();
	}

	get accessToken(): string {
		return this.oauthService.getAccessToken();
	}

	get stateUrl(): string | undefined {
		return this._stateUrl;
	}

	get user(): User {
		return this._authorizationResult ? this.authorizationResult.user : new User();
	}

	get authorizationResult(): AuthorizationResult {
		return this._authorizationResult;
	}

	get hasValidAccessToken(): boolean {
		if (!this.oauthService.hasValidAccessToken()) return false;

		const accessTokenExpiration = this.oauthService.getAccessTokenExpiration();
		if (!accessTokenExpiration) return false;

		return this.isInTheFuture(accessTokenExpiration);
	}
	constructor() {
		this.registerEvents();
	}

	configure(config: AppConfig): void {
		// This method should be called only once
		if (this._isIdentityConfigurationDone) return;

		const authConfig: AuthConfig = config.useRopcFlow
			? new ROPCAuthConfig(config, environment.production)
			: new CodeFlowAuthConfig(config, environment.production);

		this.oauthService.configure(authConfig);
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
		this._useRopcFlow = config.useRopcFlow;

		this._isIdentityConfigurationDone = true;
	}

	async initialize(): Promise<boolean> {
		try {
			await this.loadDiscoveryDocument();

			//
			const loginOption = new LoginOptions();
			loginOption.preventClearHashAfterLogin = false;

			let isLoginSuccess = await this.oauthService.tryLogin(loginOption);
			// no valid access token --> try get one with silent refresh
			if (!this.hasValidAccessToken && this.oauthService.useSilentRefresh) {
				console.debug("No valid access token --> try to get a valid one through silent refresh");
				isLoginSuccess = await this.silentRefresh(false);
			}

			if (!this.hasValidAccessToken) {
				this.notifyAuthorizationResultChange();
				return true;
			}
			// set up the automatic silent refresh
			if (isLoginSuccess) {
				await this.oauthService.loadUserProfile();
				// set up the automatic silent refresh
				this.oauthService.setupAutomaticSilentRefresh();
			} else {
				this.notifyAuthorizationResultChange();
			}

			this.tryRegisterRedirectFromUrl();

			// notify that identity is up
			this._isIdentityServerOnErrorSubject.next(false);

			return true;
		} catch (error: unknown) {
			if (!(error instanceof OAuthErrorEvent)) {
				this._isIdentityServerOnErrorSubject.next(true);
				return false;
			}

			const errorReason: OAuthErrorEventParams | null = error?.params;
			if (!errorReason || errorReason.error !== "login_required") {
				this._isIdentityServerOnErrorSubject.next(true);
				return false;
			}

			return true;
		}
	}

	// Code Flow
	login(targetUrl?: string): void {
		this.oauthService.initCodeFlow(targetUrl || this.router.url);
	}

	async tryForceLogin(targetUrl?: string): Promise<void> {
		let customRedirectUri: string | undefined = targetUrl || this.router.url;
		customRedirectUri = customRedirectUri !== "/" ? customRedirectUri : undefined;
		await this.oauthService.initLoginFlow(customRedirectUri);
	}

	async logout(): Promise<void> {
		try {
			await this.oauthService.revokeTokenAndLogout();
		} catch (e: unknown) {
			console.error(e);
		}
	}

	// Password Flow
	async ropcLogin(userName: string, password: string): Promise<boolean> {
		try {
			await this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(userName, password);
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	async ropcLogout(): Promise<void> {
		try {
			await this.oauthService.logOut(true);
		} catch (e: unknown) {
			console.error(e);
		}
	}

	impersonateThirdParty(
		currentRentalId: string,
		familyName: string,
		givenName: string,
		roles: string[],
		isImpersonate: boolean,
		isRegistered: boolean,
		rentalIds: string[],
		userName: string,
		thirdPartyId: string
	): void {
		this.authorizationResult.user.impersonateThirdParty(
			currentRentalId,
			familyName,
			givenName,
			roles,
			isImpersonate,
			isRegistered,
			rentalIds,
			userName,
			thirdPartyId
		);
		this.notifyAuthorizationResultChange();
	}

	// Private function
	private async silentRefresh(forceLoginIfFailed = true): Promise<boolean> {
		try {
			await this.oauthService.silentRefresh();

			return true;
		} catch (silentRefreshError: any) {
			if (!forceLoginIfFailed) {
				console.debug("Silent refresh failed");
				return false;
			}
			// Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
			// Only the ones where it's reasonably sure that sending the
			// user to the IdServer will help.
			const errorResponsesRequiringUserInteraction = [
				"interaction_required",
				"login_required",
				"account_selection_required",
				"consent_required",
			];

			if (
				silentRefreshError &&
				silentRefreshError.reason &&
				silentRefreshError.reason.params &&
				errorResponsesRequiringUserInteraction.indexOf(silentRefreshError.reason.params.error) >= 0
			) {
				// 3. ASK FOR LOGIN:
				// At this point we know for sure that we have to ask the
				// user to log in, so we redirect them to the IdServer to
				// enter credentials.
				// Enable this to ALWAYS force a user to login.
				if (this._useRopcFlow) return false;
				// Enable this to ALWAYS force a user to login.
				this.login();
				//
				// Instead, we'll now do this:
				return true;
			}
			// We can't handle the truth, just pass on the problem to the
			// next handler.
			return false;
		}
	}

	private tryRegisterRedirectFromUrl(): void {
		if (!this.oauthService.state || this.oauthService.state === "undefined" || this.oauthService.state === "null")
			return;

		let currentStateUrl = this.oauthService.state;

		if (!currentStateUrl.startsWith("/")) currentStateUrl = decodeURIComponent(currentStateUrl);

		this._stateUrl = currentStateUrl;
	}

	private buildUser(): User {
		const claims = this.oauthService.getIdentityClaims();

		if (!claims) return new User();

		const user = new User({
			id: claims["sub"],
			familyName: claims["family_name"],
			givenName: claims["given_name"],
			roles: claims["role"],
			thirdPartyId: claims["third_party_id"],
			username: claims["user_name"],
		});

		if (claims["rental_id"] && claims["rental_id"].length !== 0) {
			user.currentRentalId = Array.isArray(claims["rental_id"]) ? claims["rental_id"][0] : claims["rental_id"];
		}
		return user;
	}

	private buildAuthorizationResult(): AuthorizationResult {
		if (this._isIdentityServerOnError) {
			return new AuthorizationResult(true, true, false);
		}

		const isAuthenticated = this.hasValidAccessToken;

		if (!isAuthenticated) {
			return new AuthorizationResult(true, false, false);
		}

		const user = this.buildUser();
		return new AuthorizationResult(true, false, true, user);
	}

	private notifyAuthorizationResultChange(): void {
		this._authorizationResult = this.buildAuthorizationResult();
		this._authorizationResultSubject.next(this._authorizationResult);
	}

	private handleSuccessEvents(e: OAuthSuccessEvent): void {
		console.debug(e);

		if (e.type === "user_profile_loaded") {
			this.notifyAuthorizationResultChange();
		}
	}
	private handleInfoEvents(e: OAuthInfoEvent): void {
		console.debug(e);
	}

	private async handleErrorEvents(e: OAuthErrorEvent): Promise<void> {
		console.error(e);

		if (e.type === "user_profile_load_error") {
			this.forceRemoveTokens();
			return;
		}
	}

	private forceRemoveTokens(): void {
		this.oauthStorage.removeItem("refresh_token");
		this.oauthStorage.removeItem("access_token");
		this.oauthStorage.removeItem("id_token");
	}

	private async loadDiscoveryDocument(): Promise<void> {
		if (this._isLoadDiscoveryDocumentDone) return;

		await this.oauthService.loadDiscoveryDocument();
		this._isLoadDiscoveryDocumentDone = true;
	}

	private registerEvents(): void {
		this._onAuthErrorEvents$.subscribe();
		this._onAuthInfoEvents$.subscribe();
		this._onAuthSuccessEvents$.subscribe();
		this._onRetryOnFail$.subscribe();
		this._onAuthSilentRefreshEvents$.subscribe();
	}

	private isInTheFuture(value: number): boolean {
		const duration: number = value - new Date().getTime();
		return duration > 0;
	}
}

type OAuthErrorEventParams = {
	error?: string;
};
